// BranchContext.js
import React, { createContext, useState, useContext } from 'react';

const BranchContext = createContext();

export const BranchProvider = ({ children }) => {
    const [selectedBranchId, setSelectedBranchId] = useState('');

    return (
        <BranchContext.Provider value={{ selectedBranchId, setSelectedBranchId }}>
            {children}
        </BranchContext.Provider>
    );
};

export const useBranch = () => useContext(BranchContext);
