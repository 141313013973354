import React, { useState, useEffect } from 'react';
import { FaSearch } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { IoMdAddCircle } from "react-icons/io";
import { getAllBranches, addNewBranch, deleteBranchById } from '../services/productService';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { showSuccessAlert, showDeleteConfirmation } from './Alert';
import '../styles/Branch.css';
import { HiOutlinePencilSquare } from "react-icons/hi2";

const Branch = () => {
    const [branches, setBranches] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [showBranchForm, setShowBranchForm] = useState(false);
    const [newBranch, setNewBranch] = useState({
        name: '',
        phone: '',
        email: '',
        address: {
            country: '',
            state: '',
            city: '',
            primary_street: '',
            secondary_street: ''
        }
    });
    const [formStep, setFormStep] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        const fetchBranches = async () => {
            try {
                const data = await getAllBranches();
                setBranches(data);
            } catch (error) {
                console.error('Error fetching branches:', error);
            }
        };
        fetchBranches();
    }, []);

    const handleViewBranchDetails = (branch) => {
        setSelectedBranch(branch);
    };

    const closeCard = () => {
        setSelectedBranch(null);
    };

    const isValidEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const toggleBranchForm = () => {
        setShowBranchForm(!showBranchForm);
        setErrorMessage('');
        setSuccessMessage('');
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.includes('address.')) {
            const addressField = name.split('.')[1];
            setNewBranch(prevState => ({
                ...prevState,
                address: {
                    ...prevState.address,
                    [addressField]: value
                }
            }));
        } else {
            setNewBranch(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleNextStep = () => {
        if (!newBranch.name || !newBranch.phone || !isValidEmail(newBranch.email)) {
            setErrorMessage('Por favor complete todos los campos.');
            return;
        }
        setErrorMessage('');
        setFormStep(2);
    };

    const handlePrevStep = () => {
        setFormStep(1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isValidEmail(newBranch.email)) {
            setErrorMessage('Por favor ingrese un correo electrónico válido.');
            return;
        }

        console.log('Datos a enviar:', newBranch);
        try {
            const response = await addNewBranch(newBranch);
            console.log('New branch added successfully:', response);
            setBranches([...branches, response]);
            setShowBranchForm(false);
            setNewBranch({
                name: '',
                phone: '',
                email: '',
                address: {
                    country: '',
                    state: '',
                    city: '',
                    primary_street: '',
                    secondary_street: ''
                }
            });
            setSuccessMessage('Sucursal agregada exitosamente.');
            // Muestra el modal de éxito
            showSuccessAlert('Sucursal agregada exitosamente.');
            // Regresa al Paso 1
            setFormStep(1);
        } catch (error) {
            console.error('Error adding new branch:', error);
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('Error al agregar la sucursal. Por favor intente nuevamente.');
            }
        }
    };

    const handleDeleteBranch = (branchId) => {
        showDeleteConfirmation(async () => {
            try {
                await deleteBranchById(branchId);
                setBranches(branches.filter(branch => branch.id !== branchId));
                showSuccessAlert('Sucursal eliminada correctamente.');
            } catch (error) {
                console.error('Error deleting branch:', error);

            }
        });
    };
    return (
        <div>
            <div className="search-bar">
                {/* <div className="search-input">
                    <input type="text" placeholder="Buscar..." />
                    <FaSearch className="search-icon" />
                </div> */}
                <div className="add-options">
                    <div className="add-option" onClick={toggleBranchForm}>
                        <IoMdAddCircle className="add-icon" />
                        <span>Agregar Sucursal</span>
                    </div>
                </div>
            </div>
            <div className="card-container">
                <div className="card">
                    <h2>LISTADO DE SUCURSALES</h2>
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nombre</th>
                                    <th>Teléfono</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {branches.map(branch => (
                                    <tr key={branch.id}>
                                        <td>{branch.id}</td>
                                        <td>{branch.name}</td>
                                        <td>{"+" + branch.phone}</td>
                                        <td>
                                            <span className="action-button" onClick={() => handleDeleteBranch(branch.id)}>
                                                <FaTrashAlt className="icon-pencil__1" />
                                            </span>

                                            {/* <span className="action-button">
                                                <HiOutlinePencilSquare  className="icon-pencil__2" />
                                            </span> */}
                                            <button className="details-button" onClick={() => handleViewBranchDetails(branch)}>
                                                Ver detalles
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {selectedBranch && (
                <div className="floating-card">
                    <div className="card-content">
                        {/* <button className="close-button" onClick={closeCard}><IoMdClose /></button> */}
                        <h3>Detalles de la Sucursal - {selectedBranch.name}</h3>
                        {/* <p><strong>ID:</strong> {selectedBranch.id}</p> */}
                        <p><strong>Nombre:</strong> {selectedBranch.name}</p>
                        <p><strong>Teléfono:</strong> {selectedBranch.phone}</p>
                        <p><strong>Email:</strong> {selectedBranch.email}</p>
                        <p><strong>Dirección:</strong> {selectedBranch.address.primary_street}, {selectedBranch.address.city}, {selectedBranch.address.state}</p>
                    </div>
                    <button type="button" onClick={closeCard} className='button_danger'>Salir</button>
                </div>
            )}
            {showBranchForm && (
                <div className="modal-background">
                    <form onSubmit={handleSubmit}>
                        <div className="floating-card">
                            {formStep === 1 && (
                                <>
                                    <h3>Paso 1: Información Básica</h3>
                                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                                    <div className="form-group">
                                        <label htmlFor="branchName">Nombre de la sucursal</label>
                                        <input type="text" id="branchName" placeholder="Nombre de la sucursal" name="name" value={newBranch.name} onChange={handleChange} required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="branchPhone">Teléfono</label>
                                        <PhoneInput
                                            inputProps={{
                                                id: 'branchPhone',
                                                name: 'phone',
                                                required: true,
                                                autoFocus: true
                                            }}
                                            country={'ec'}
                                            value={newBranch.phone}
                                            onChange={phone => setNewBranch(prevState => ({ ...prevState, phone }))}
                                            inputStyle={{ width: '100%' }}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="branchEmail">Email</label>
                                        <input
                                            type="email"
                                            id="branchEmail"
                                            placeholder="Email"
                                            name="email"
                                            value={newBranch.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    <div className="button-group">
                                        <button type="button" onClick={toggleBranchForm} className='button_danger'>Cancelar</button>
                                        <button type="button" onClick={handleNextStep} className='button_primary'>Siguiente</button>
                                    </div>
                                </>
                            )}
                            {formStep === 2 && (
                                <>
                                    <h3>Paso 2: Dirección de la Nueva Sucursal</h3>
                                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                                    {successMessage && <p className="success-message">{successMessage}</p>}
                                    <div className="form-group">
                                        <label htmlFor="branchCountry">País</label>
                                        <input type="text" id="branchCountry" placeholder="País" name="address.country" value={newBranch.address.country} onChange={handleChange} required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="branchState">Estado</label>
                                        <input type="text" id="branchState" placeholder="Estado" name="address.state" value={newBranch.address.state} onChange={handleChange} required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="branchCity">Ciudad</label>
                                        <input type="text" id="branchCity" placeholder="Ciudad" name="address.city" value={newBranch.address.city} onChange={handleChange} required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="branchPrimaryStreet">Calle Principal</label>
                                        <input type="text" id="branchPrimaryStreet" placeholder="Calle Principal" name="address.primary_street" value={newBranch.address.primary_street} onChange={handleChange} required />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="branchSecondaryStreet">Calle Secundaria</label>
                                        <input type="text" id="branchSecondaryStreet" placeholder="Calle Secundaria" name="address.secondary_street" value={newBranch.address.secondary_street} onChange={handleChange} required />
                                    </div>
                                    <div className="button-group">
                                        <button type="button" onClick={handlePrevStep} className='button_danger'>Atrás</button>
                                        <button type="submit" className='button_primary'>Guardar</button>
                                    </div>
                                </>
                            )}
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
}

export default Branch;
