import React from 'react'

import '../styles/content.css'
import Card from './Card'
const Content = () => {
  return (
    <Card />
  )
}

export default Content
